<template>
  <div>
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <!-- <label>Show</label> -->
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t("Show") }}</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                :placeholder="$t('Search...')"
              />
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        hover
        ref="refUserListTable"
        class="position-relative"
        :per-page="perPage"
        :current-page="currentPage"
        :items="filtered_activity_data"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="$t('No Matching Creates Found')"
        :sort-desc.sync="isSortDirDesc"
      >
        <template slot="my-link" slot-scope="data">
          <b-link :href="data.item.link">{{ $t("link") }}</b-link>
        </template>

        <!-- <template #cell(işlemler)="data">
          <b-button
            size="sm"
            class="mr-1"
            variant="outline-primary"
            :to="{
              name: 'activityEdit',
              params: { activityID: data.item.activity_id },
            }"
            >{{ $t("Edit") }}</b-button>
          <b-button
            size="sm"
            variant="outline-warning"
            :to="{
              name: 'activityEdit',
              params: { activityID: data.item.activity_id },
            }"
            >{{ $t("Deactivate") }}</b-button
          >
        </template> -->
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="6"
            sm=""
            class="
              d-flex
              align-items-right
              justify-content-right justify-content-sm-end
              radius 0
            "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="total_item"
              :per-page="perPage"
              first-number
              last-number
              align="right"
              class="mb-0 mt-1 mt-sm-0 r-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BFormSelect,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BBreadcrumb,
  BBreadcrumbItem,
  BFormCheckbox,
} from "bootstrap-vue";
import vSelect from "vue-select";
import DataBase from "@/services/DataBase.js";
import { dateFormat } from '@/mixins/functions';
import MoneyFormat from "@/functions/MoneyFormat"

export default {
  name: "activityList",
  components: {
    BFormSelect,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BBreadcrumb,
    BBreadcrumbItem,
    BFormCheckbox,
    vSelect
  },
  data() {
    return {
      bgcolor: "primary",
      searchQuery: "",
      currentPage: null, //
      total_item: null,
      isSortDirDesc: true,
      sortBy: "id",
      search_query: "",
      perPage: 25,
      locale: this.$i18n.locale,
      perPageOptions: [10, 25, 50, 100],
      tableColumns: [
        { key: "price", label: this.$t("PRICE"), sortable: true },
        {
          key:"session",
          label: this.$t("Session Count"), sortable: true
        },
        {
          key: "created_date",
          label: this.$t("CREATED DATE"),
          sortable: true
        },
        {
          key: "updated_date",
          label: this.$t("UPDATED DATE"),
          sortable: true
        }
        // { key: "işlemler", label: this.$t("Transactions"), sortable: false },
      ],
      productData: [],

      selected: 'tüm',
      trainer_id: null
    };
  },
  computed: {
    filtered_activity_data() {
      let data = [];
      this.productData.map((x) => {
        //3 field içerisinde birden arama yapılabilsin
        if ((x.price + " " + x.created_date + " " + x.updated_date).includes(this.searchQuery)) {
          data.push(x);
          this.total_item = data.length;
          this.currentPage=1;
        }
      });
      return data
    },
  },
  watch: {
    productData(val) {
      this.total_item = val.length;
    },
  },
  methods: {
    getPriceListProduct(){
        this.$database.StudioService.get_price_list_product(
            this.$route.params.productID
        ).then(res => {
            if(!res.is_success) return;
            this.productBaseData = res.result;
            this.productData =  res.result;
            this.productBaseData.map(x => {
              if(x.created_date){
                x.created_date = dateFormat(x.created_date)

              }
              if(x.updated_date){
                x.updated_date = dateFormat(x.updated_date)
              }
              if(x.price){
                x.price = MoneyFormat(x.price)
              }
            })
            this.total_product = res.result.length;
            this.currentPage = 1;
        })
    }
  },
  created() {
    this.getPriceListProduct();
  },
};
</script>

<!-- stillendirme -->
<style lang="scss" src="@/styles/scss/instructorProductsPriceListScoped.scss" scoped></style>
